import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const Verificacion = () => {
  const [estado, setEstado] = useState('verificando');

  const token = localStorage.getItem("AuthToken");
  let tokenInfo = {};

  if (token) {
    tokenInfo = jwtDecode(token);
  } else {
    console.error("Token no encontrado");
    setEstado('error');
  }

  const handleLogout = () => {
    localStorage.removeItem("AuthToken");
    window.location.reload();
  };

  useEffect(() => {
    if (tokenInfo.usuario) {
      const obtenerInfoUsuario = async () => {
        try {
          const respuesta = await axios.get(`https://apisoporte.agenciaadhoc.com/usuario/info/${tokenInfo.usuario}`);
          const usuario = respuesta.data.usuarios;

          if (usuario.estado === 'verificado') {
            setEstado('verificado');
          } 
        } catch (error) {
          console.error('Error al obtener la información del usuario:', error);
          setEstado('error');
        }
      };

      // Ejecutar la verificación al cargar el componente
      obtenerInfoUsuario();

      // Crear un intervalo que ejecute la verificación cada 5 segundos
      const intervalo = setInterval(() => {
        obtenerInfoUsuario();
      }, 5000);

      // Limpiar el intervalo cuando el componente se desmonte
      return () => clearInterval(intervalo);
    }
  }, [tokenInfo.usuario]);

  return (
    <main className='site-width verificando'>
      <div className="verificar-card">
        {estado === 'verificado' ? (
          <>
            <img src="/images/aplausos.svg" alt="Icono" />
            <h2>¡Cuenta Verificada!</h2>
            <p>Tu cuenta de cliente ha sido verificada exitosamente.<br/>Vuelva a iniciar sesión para confirmar la verificación.</p>
            <button className='terminar-verificacion' onClick={handleLogout}>
                Terminar la verificación
            </button>
          </>
        ) : estado === 'error' ? (
          <>
            <img src="/images/mensaje-de-error.svg" alt="Icono" />
            <h2>Error en la Verificación</h2>
            <p>Hubo un problema al verificar tu cuenta. Por favor, intenta de nuevo más tarde.</p>
          </>
        ) : (
          <>
            <img src="/images/atras-en-el-tiempo.svg" alt="Icono" />
            <h2>Verificación en proceso</h2>
            <p>Estamos gestionando la verificación de tu cuenta de cliente, por favor, espere.</p>
          </>
        )}
      </div>
    </main>
  );
}

export default Verificacion;
