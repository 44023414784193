import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const TicketCreado = () => {
    const { identificador } = useParams(); // Obtener el ID del ticket de los parámetros de la URL
    const [ticket, setTicket] = useState(null);

    const token = localStorage.getItem("AuthToken");

    // Comprobar si el token está presente antes de intentar decodificarlo
    let tokenInfo = {};
    if (token) {
      tokenInfo = jwtDecode(token);
    }

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const response = await fetch(`https://apisoporte.agenciaadhoc.com/ticket/${identificador}`);
                console.log('Response:', response);
                const data = await response.json();
                console.log('Data:', data);
                if (data.ticket.usuario_creador == tokenInfo.usuario) {
                    setTicket(data.ticket);
                    console.log("Coincide")
                } else {
                    console.error('Error al obtener el ticket:', data.message);
                }
            } catch (error) {
                console.error('Error al obtener el ticket:', error);
            }
        };
    
        fetchTicket();
    }, [identificador]);
    

    return (
        <main className='ticket-creado site-width'>
            <div className="bloque-ticket-creado">

                {ticket ? (
                    <>
                        <img src="/images/flecha-enviado.gif" alt="Flecha enviado" />
                        <h2>¡Gracias por enviar tu incidencia!</h2>
                        <p>Acabamos de recibir tu ticket, será asignado a un técnico en breve.</p>
                        <p className='identificador'>Identificador: {ticket.identificador}</p>

                        <p className='recordatorio'>Recuerda que atendemos dentro de horario laboral por orden de llegada.</p>
                        {/* Aquí puedes agregar más detalles del ticket si lo deseas */}
                    </>
                ) : (
                    <>
                        <img src="/images/oso.gif" alt="Flecha enviado" />
                        <h2>¡Oops! No tienes permisos</h2>
                        <p>Si crees que este mensaje es un error, <a href='tel:+34 856 901 276'>contáctanos</a>.</p>
                    </>               
                 )}
            </div>
        </main>
    );
};

export default TicketCreado;
