import React from 'react'

const Footer = () => {
  return (
    <footer>
      <a href='https://www.agenciaadhoc.com' target='_blank'>www.agenciaadhoc.com</a>
    </footer>
  )
} 

export default Footer
