import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmpiezaAhora = () => {
  const [step, setStep] = useState(1); // Estado para manejar el paso actual del formulario
  const [formData, setFormData] = useState({
    usuario: '',
    contrasena: '',
    confirmarContrasena: '', // Nuevo campo para la confirmación de contraseña
    nombre: '',
    apellidos: '',
    telefono: '',
    email: '',
    webs_del_cliente: '',
    fotoPerfil: 'https://soporte.agenciaadhoc.com/images/usuario.png',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Manejar cambio de los inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Manejar cambio de archivo para la foto de perfil
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      fotoPerfil: e.target.files[0],
    });
  };

  // Mostrar notificaciones de error
  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Validaciones
  const validateStep = (currentStep) => {
    if (currentStep === 1) {
      const usuarioRegex = /^[a-z0-9_]+$/;
      if (!usuarioRegex.test(formData.usuario)) {
        showError('El nombre de usuario no permite espacios, letras mayúsculas y caracteres especiales. Solamente acepta letras minúsculas, números y guiones bajos.');
        return false;
      }
      if (formData.usuario.length < 3) {
        showError('El nombre de usuario debe tener al menos 3 caracteres.');
        return false;
      }
      if (formData.contrasena.length < 5) {
        showError('La contraseña debe tener al menos 5 caracteres.');
        return false;
      }
      if (formData.contrasena !== formData.confirmarContrasena) {
        showError('Las contraseñas no coinciden.');
        return false;
      }
    }
    if (currentStep === 2) {
      if (!formData.nombre) {
        showError('El campo de nombre no puede estar vacío.');
        return false;
      }
      if (!formData.apellidos) {
        showError('El campo de apellidos no puede estar vacío.');
        return false;
      }
    }
    if (currentStep === 3) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        showError('Por favor, introduce un email válido.');
        return false;
      }
      const telefonoRegex = /^[0-9+()\/\-\s]*$/;
      if (!telefonoRegex.test(formData.telefono)) {
        showError('El teléfono solo puede contener números y los caracteres +, (, ), -, /.');
        return false;
      }
      if (!formData.telefono) {
        showError('El campo de teléfono no puede estar vacío.');
        return false;
      }
      if (!formData.webs_del_cliente) {
        showError('El campo de webs incluidas en el mantenimiento no puede estar vacío.');
        return false;
      }
    }
    return true;
  };

  // Función para cambiar de paso
  const changeStep = (newStep) => {
    // Solo validar cuando el usuario avanza, no cuando retrocede
    if (newStep > step && !validateStep(step)) {
      return;
    }
    setStep(newStep);
  };

  // NOTIFICAR REGISTRO
  const notificarAdministrador = async () => {
    try {
      // Llamar a la API del backend para notificar al administrador
      await axios.post('https://apisoporte.agenciaadhoc.com/notificar-registro', {
        usuario: formData.usuario,
        nombre: formData.nombre,
        apellidos: formData.apellidos,
        webs_aseguradas: formData.webs_del_cliente
      });
      console.log('Notificación enviada al administrador');
    } catch (error) {
      console.error('Error al enviar la notificación:', error);
    }
  };
  // Enviar formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(3)) return;

    setLoading(true);
    
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key] || '');
    });

    // Si no se selecciona una foto de perfil, usar la imagen por defecto
    if (!formData.fotoPerfil) {
      formDataToSend.append('fotoPerfil', 'https://soporte.agenciaadhoc.com/images/usuario.png');
    }

    try {
      const response = await axios.post('https://apisoporte.agenciaadhoc.com/crear-usuario', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess(true);

      // Enviar notificación al administrador después del registro exitoso
      await notificarAdministrador();

    } catch (err) {
      showError('Error al crear el usuario');
    } finally {
      setLoading(false);
    }
  };

  // Calcular el porcentaje de la barra de progreso
  const progressPercentage = (step / 4) * 100;

  return (
    <main className="site-width">
      <ToastContainer />
      {success ? (
        <div className="verificar-card-registro">
          <img src="/images/exito.svg" alt="Icono" />
          <h2>¡Registro completado con éxito!</h2>
          <p>Estás a un solo paso de acceder al soporte. <br/><br/> Nuestros técnicos están validando tu información y pronto verificaremos tu cuenta.<br/>Puedes consultar el estado de tu cuenta iniciando sesión con tus credenciales.</p>                
        </div>
      ) : (
        <>
          <div className='grid-registrarse'>
            {/* Barra de progreso */}
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>

            <form onSubmit={handleSubmit} className="form-container">
              <div className='mensaje-registro'>
                <h1>Empieza ahora</h1>
                <p>Crea tu cuenta de cliente para recibir soporte técnico en unos simples pasos.</p>
              </div>

              {step === 1 && (
                <div className='grid-step'>
                  <div className="content-step">
                    <h2>Paso 1: Acceso al soporte</h2>
                    <p>Con las credenciales que aportes aquí, accederás a tu panel de soporte.</p>
                  </div>
                  <div className="step-group">
                    <label>
                      Nombre de Usuario:
                      <span>El nombre de usuario se asociará a todas tus acciones en el soporte, debe de ser concreto y lo más corto posible. (Ejemplo: agenciaadhoc)</span>
                      <input
                        type="text"
                        name="usuario"
                        value={formData.usuario}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label>
                      Contraseña:
                      <input
                        type="password"
                        name="contrasena"
                        value={formData.contrasena}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label>
                      Confirmar Contraseña:
                      <input
                        type="password"
                        name="confirmarContrasena"
                        value={formData.confirmarContrasena}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </div>
                  <button type="button" onClick={() => changeStep(2)}>
                    Siguiente
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className='grid-step'>
                  <div className="content-step">
                    <h2>Paso 2: Datos Personales</h2>
                    <p>¿Cómo nos dirigimos hacia ti?</p>
                  </div>
                  <div className="step-group">
                    <label>
                      Nombre:
                      <input
                        type="text"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label>
                      Apellidos:
                      <input
                        type="text"
                        name="apellidos"
                        value={formData.apellidos}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-step-buttons">
                    <button type="button" onClick={() => changeStep(1)}>
                      Anterior
                    </button>
                    <button type="button" onClick={() => changeStep(3)}>
                      Siguiente
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className='grid-step'>
                  <div className="content-step">
                    <h2>Paso 3: Información Adicional</h2>
                    <p>Con la siguiente información, podemos contactarte y establecer tus servicios contratados.</p>
                  </div>
                  <div className="step-group">
                    <label>
                      Email:
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label>
                      Teléfono:
                      <span>Incluya su código de país. (Ejemplo: +34 600 00 00 00)</span>

                      <input
                        type="tel"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label>
                      Tus páginas webs incluidas en los mantenimientos:
                      <span>En caso de ser más de una, sepárelas con comas. (Ejemplo: primerdominio.com, segundodominio.com...)</span>
                      <input
                        type="text"
                        name="webs_del_cliente"
                        value={formData.webs_del_cliente}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-step-buttons">
                    <button type="button" onClick={() => changeStep(2)}>
                      Anterior
                    </button>
                    <button type="button" onClick={() => changeStep(4)}>
                      Siguiente
                    </button>
                  </div>
                </div>
              )}

              {step === 4 && (
                <div className='grid-step'>
                  <div className="content-step">
                    <h2>Paso 4: Foto de Perfil <span className='opcional'>(Opcional)</span></h2>
                    <p>¡Queremos verte! Todos nuestros técnicos usan su foto de perfil :).<br/>En caso de no elegir foto de perfil, se te asignará un avatar predeterminado.</p>
                  </div>
                  <div className="step-group">
                    <label>
                      Foto de perfil:
                      <input type="file" name="fotoPerfil" onChange={handleFileChange} />
                    </label>
                  </div>
                  <div className="grid-step-buttons">
                    <button type="button" onClick={() => changeStep(3)}>
                      Anterior
                    </button>
                    <button type="submit" disabled={loading}>
                      {loading ? 'Enviando...' : 'Registrarse'}
                    </button>
                  </div>
                </div>
              )}

            </form>
          </div>
        </>
      )}
    </main>
  );
};

export default EmpiezaAhora;
