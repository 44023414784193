import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminPrePanel = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [longitudUsuarios, setLongitudUsuarios] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get('https://apisoporte.agenciaadhoc.com/todos-usuarios');
        const usuarios = response.data.usuarios;
        setUsuarios(usuarios);
        setLongitudUsuarios(usuarios.length);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };

    const fetchTotalTickets = async () => {
      try {
        const response = await axios.get('https://apisoporte.agenciaadhoc.com/tickets/total');
        const tickets = response.data.tickets; // Supone que "tickets" es un array de objetos.
        setTotalTickets(tickets.length); // Cuenta el número de tickets.
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
      }
    };

    fetchUsuarios();
    fetchTotalTickets();
  }, []);

  return (
    <div className='admin-prepanel'>
        <Link to={'/dashboard/tickets-manager'} className='prepanel-card'>
            <h3>Tickets creados</h3>
            <p className='prepanel-number'>{totalTickets}</p>
            <button>Administrar</button>
        </Link>
        <Link to={'/dashboard/users-manager'} className='prepanel-card'>
            <h3>Usuarios en el sistema</h3>
            <p className='prepanel-number'>{longitudUsuarios}</p>
            <button>Administrar</button>
        </Link>
    </div>
  );
};

export default AdminPrePanel;
