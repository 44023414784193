import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserManager = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]); // Estado para usuarios filtrados
    const [searchQuery, setSearchQuery] = useState(''); // Estado para el input de búsqueda
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({}); // Estado para el formulario
    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
    const usersPerPage = 10; // Usuarios por página

    const token = localStorage.getItem('AuthToken');
    let tokenInfo = {};
    if (token) {
        tokenInfo = jwtDecode(token);
    }

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://apisoporte.agenciaadhoc.com/todos-usuarios');
                if (Array.isArray(response.data.usuarios)) {
                    // Ordenar de más recientes a más antiguos
                    const sortedUsers = response.data.usuarios.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setUsers(sortedUsers);
                    setFilteredUsers(sortedUsers); // Inicializar usuarios filtrados
                } else {
                    console.error('La respuesta de la API no es un array:', response.data);
                    setUsers([]);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setUsers([]);
            }
        };
        fetchUsers();
    }, [token]);

    const handleVer = async (usuario) => {
        try {
            const response = await axios.get(`https://apisoporte.agenciaadhoc.com/usuario/info/${usuario}`);
            setSelectedUser(response.data.usuarios);
            setFormData(response.data.usuarios); // Inicializa el formulario con los datos del usuario
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    // Manejar los cambios en los inputs del formulario
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ 
            ...formData, 
            [name]: value === 'null' ? null : value  // Si el valor es 'null', asigna null, si no, asigna el valor seleccionado.
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(
                `https://apisoporte.agenciaadhoc.com/usuario/update/${selectedUser.usuario}`,
                formData,
                {
                    headers: {
                        Authorization: token 
                    }
                }
            );            
            if (response.data.success) {
                setSelectedUser(response.data.user);
                
                // Actualizar el usuario en la lista de usuarios
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user.usuario === response.data.user.usuario ? response.data.user : user
                    )
                );

                // Muestra el toast de éxito
                toast.success('Usuario actualizado correctamente', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'colored',
                    progress: undefined,
                });

                closeModal(); // Cierra el modal
            }
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
        }
    };

    // Filtrar usuarios basados en la búsqueda
    useEffect(() => {
        const filtered = users.filter(user =>
            user.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.apellidos.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.usuario.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchQuery, users]);

    // Calcular el índice de los usuarios para la paginación
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <main className='site-width'>
            <div className="dashboard-content">
                <h2>Usuarios en el sistema</h2>
                <p>Gestiona los usuarios del sistema.</p>

                {/* Input de búsqueda */}
                <input 
                    type="text" 
                    placeholder="Buscar por nombre, apellidos o usuario" 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)} 
                    className="search-input"
                />

                <div className="tabcontent">
                    <div className='grid-usuarios'>
                        {currentUsers.length > 0 ? (
                            currentUsers.map((user) => (
                                <div key={user.id} className="user-card">
                                    <div className="user-image-grid">
                                        <img className="user-img" src={user.fotoPerfil} alt="Perfil" />   
                                        <p>{user.estado === "verificado" ? <img className='verificacion' src='/images/verificado.svg' alt="Icono"/> : <img className='verificacion' src='/images/no-verificado.svg' alt="Icono"/> }</p>
                                    </div>
                                    <div className="user-data">
                                        <h3>{user.nombre} {user.apellidos}</h3>
                                        <p className='user-usuario'>@{user.usuario}</p>
                                        {
                                            user.role == "user" && <p className='user-rol user-rol-user'><b>Rol:</b> Usuario</p>
                                        }
                                                                                {
                                            user.role == "admin" && <p className='user-rol user-rol-admin'><b>Rol:</b> Administrador</p>
                                        }
                                        <p className="user-fecha">                                        
                                            <strong>Se unió el:</strong> {new Date(user.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                    <div className="user-botones">
                                        <button onClick={() => handleVer(user.usuario)}>Ver más</button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-users">No se encontraron usuarios.</div>
                        )}
                    </div>
                </div>

                {/* Paginación */}
                <div className="pagination users-pagination">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Siguiente
                    </button>
                </div>
            </div>

            {/* Modal Pop-up */}
            {showModal && selectedUser && (
                <div className="modal-overlay show">
                    <div className="modal-content show">
                        <button className="modal-close" onClick={closeModal}>X</button>
                        <form onSubmit={handleSubmit}>
                            <div className="user-details">
                                <img className="selected-user-img" src={selectedUser.fotoPerfil} alt="Foto de perfil"/>   
                                <h3>
                                    <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange}/>
                                    <input type="text" name="apellidos" value={formData.apellidos} onChange={handleInputChange}/>
                                </h3>
                                <p>@{selectedUser.usuario}</p>
                                <div className="user-details-data">
                                    <div className="user-details-field">
                                        <p>Teléfono</p>
                                        <input type="text" name="telefono" value={formData.telefono} onChange={handleInputChange}/>
                                    </div>
                                    <div className="user-details-field">
                                        <p>Email</p>
                                        <input type="email" name="email" value={formData.email} onChange={handleInputChange}/>
                                    </div>
                                    <div className="user-details-field">
                                        <p>Estado</p>
                                        <select name="estado" value={formData.estado} onChange={handleInputChange}>
                                            <option value="verificado">Verificado</option>
                                            <option value="verificar">Sin Verificar</option>
                                        </select>
                                    </div>
                                    <div className="user-details-field">
                                        <p>Rol</p>
                                        <select name="role" value={formData.role} onChange={handleInputChange}>
                                            <option value="user">Usuario</option>
                                            <option value="admin">Administrador</option>
                                        </select>
                                    </div>
                                    <div className="user-details-field">
                                        <p>Dpto técnico asignado</p>
                                            <select name="dpto_tecnico" value={formData.dpto_tecnico} onChange={handleInputChange}>
                                                <option value="no_asignado">No asignado</option>
                                                <option value="null">Todos</option>
                                                <option value="desarrollo">Desarrollo</option>
                                                <option value="diseno">Diseño</option>
                                                <option value="marketing">Marketing</option>
                                                <option value="redes_sociales">Redes Sociales</option>
                                            </select>
                                    </div>
                                    <div className="user-details-field">
                                        <p>Servicios Holded</p>
                                        <input type="text" name="servicios_holded" value={formData.servicios_holded} onChange={handleInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <button type="submit">Guardar cambios</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Contenedor de Toastify */}
            <ToastContainer />
        </main>
    );
};

export default UserManager;
